<template>

  <div class="market-wrapper">

    <div class="market-name">
      <span class="bold">
        <img class="mr-2" style="width: 20px;" src="/menu/star.png"/>
        {{ market_name }}
      </span>
    </div>
    <div class="row">
      <Odd style="margin-bottom: 10px;" v-for="(o, index) in outcome" :key="index" v-bind:id="o.odd_id"
        v-bind:disabled="isLocked(o)" :class="getClasses(o)" v-bind:sport_id="fixture.sport_id"
        v-bind:market_name="market_name" v-bind:home_team="fixture.home_team" v-bind:away_team="fixture.away_team"
        v-bind:pick="o" v-bind:show_direction="show_direction" v-bind:match_id="fixture.match_id"
        v-bind:market_id="market_id" v-bind:outcome_id="o.outcome_id" v-bind:probability="o.probability"
        v-bind:specifier="specifier" v-bind:active="o.active" v-bind:status="status" v-bind:odds="o.odds"
        v-bind:alias="o.alias" v-bind:producer_id="fixture.producer_id" v-bind:producer_status="producer_status"
        v-bind:previous_odds="o.previous_odds" />
    </div>
    
  </div>

</template>
<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0.3rem; */
  margin: 0;
  padding: 0.3rem 0;
}

.row > .col {
  padding: 0 0.5rem;
}

.market-wrapper {
 display: flex;
 flex-direction: column;
 gap: 0.2rem;
}


/*.col {*/
/*  flex: 1 1 calc(33.333% - 4x);*/
/*  margin-bottom: 6px;*/
/*  margin-right: 6px;*/
/*  margin-left: 6px;*/
/*  padding: 0;*/
/*}*/
</style>

<script>

import Odd from './Odd'

export default {
  components: {
    Odd
  },
  name: "Outcome",
  props: {
    outcome: {
      type: Array,
      required: true
    },
    market_id: {
      required: true
    },
    market_name: {
      required: true
    },
    specifier: {
      required: true
    },
    status_name: {
      required: true
    },
    status: {
      required: true
    },
    producer_status: {
      //type: Array,
      required: true
    },
    producer_id: {
      //type: Array,
      required: true
    },
    fixture: {
      type: Object,
      required: true
    },
  },
  methods: {
    isLocked: function (o) {

      if (parseInt(this.producer_id) === 1) {

        return parseInt(this.status) > 0 || parseInt(o.active) === 0 || parseInt(this.producer_status) === 0;

      } else {

        return parseInt(this.status) > 0 || parseInt(o.active) === 0;

      }
    },
    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'specifiers-id-' + index + '-');

    },
    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */
    pick: function (sport_id, match_id, market_name, market_id, competitor_1, competitor_2, picks) {

      var vm = this;

      console.log('addPick match_id=>' + match_id + ' market_name =>' + market_name + ' market_id=>' + market_id + ' picks=>' + JSON.stringify(picks));

      var odd_id = picks.odd_id;
      var producer_id = picks.producer_id;
      var bSlip = this.getObject("bslip");

      if (!Array.isArray(bSlip)) {

        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;

      if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
      }

      if (bSlip.length > 0) {

        vm.jQuery.each(bSlip, function (k, v) {

          if (v !== undefined && v !== null) {

            if (v.odd_id === odd_id) {

              isunselect = true;
            }

            if (v.producer_id === producer_id) {

              isunselect = true;
            }

            if (v.match_id === match_id) {

              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {

        bSlip.push(picks);
      }

      var bt = [];

      vm.jQuery.each(bSlip, function (k, v) {

        if (v !== undefined && v) {

          bt.push(v);
        }

      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI(this.$vnode.tag);

    },
    getOddDirectionClass: function (outcome) {

      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },

    getClasses: function (o) {

      let count = this.outcome.length

      let col = "col"

      if (count === 2) {

        col = "col"

      } else if (count === 3) {

        col = "col"

      } else {

        col = "col-4"

      }

      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'locked btn-odd' : 'btn-odd';

      return col + " btn-odd small-txt mrs-1 mts-1 " +
        " match-" + this.fixture.match_id +
        " producer-" + this.producer_id +
        " market-" + this.market_id +
        " outcome-" + o.outcome_id +
        " specifier-" + this.specifier;

    },

  },
  mounted: function () {


  },
  data: function () {
    return {
      show_direction: false
    }
  }
}
</script>